import React from "react";
import { Link } from "gatsby";
import { Container } from "reactstrap";
// import Img from "gatsby-image";
// import { graphql } from 'gatsby';

import SearchHome from "../components/Search/SearchHome";

import Img404 from "../img/source/404OK.jpg";

const NotFoundPage = ({ data }) => (
  <>
    <Container fluid className="p404 p-0 bg-black">
      <Link to="/" className="p404-link error-image-container">
        <img
          class="p404-img"
          src={Img404}
          alt="Erreur 404 : La page que vous cherchez n'existe pas"
        />
      </Link>
    </Container>
    {/*
      <Container>
        <div className="error-page-container text-center">
          <h1 className="section-title">
            <span>404</span>
          </h1>
          <p>Nous sommes désolés, la page que vous demandez n'existe pas</p>
          <div>
            <Link to="/" className="btn btn-primary px-4">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
	  </Container>
  */}
    <div className="home-slice home-slice-search">
      <Container>
        <SearchHome SearchTitle="Comment pouvons-nous vous aider ?" />
      </Container>
    </div>
  </>
);

export default NotFoundPage;

// export const pageQuery = graphql`
//   query ErrorPages {
//     file(relativePath: { eq: "404OK.jpg" }) {
//       childImageSharp {
//         fluid(quality: 100, maxWidth: 1920) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `;
