import React from 'react'
import SearchListMini from "./SearchListMini"
import SearchPopularSearch from "./SearchPopularSearch"
import LanguageContext from "./../../state/context/LanguageContext"

// import './assets/css/Search.min.css'

const SearchHome = ({match, SearchTitle}) => (
	<div className="search-home">
		<p className="search-home-title">{SearchTitle}</p>
		<LanguageContext.Consumer>{ language => 
			<SearchListMini mySearch="" disablePopularSearch="true" placeHolderText="Rechercher une info, un service, un évènement" currentLanguage={language.language}/>
		}</LanguageContext.Consumer>
		<SearchPopularSearch />
	</div>
)

export default SearchHome
